import React, { useEffect } from 'react';
import { graphql } from 'gatsby';

// hooks & querys
import { QueryFragments } from '../GraphQl/queryFragments'; // eslint-disable-line
import { useAppContext } from '../state';

// Components
import { PageMeta } from '../components/pageMeta';
import BlockZone from '../components/blockZone/zone.development';

const PageTemplate = ({ preview = false, data, previewDoc }) => {
  const page = preview ? previewDoc : data && data.page;

  const { title, meta, pageBlocks, themeDark, hideFooter, splitNav } = page || {};

  const { setHideFooter, setSplitNav } = useAppContext();

  useEffect(() => {
    if (themeDark) {
      document.documentElement.classList.add('themeDark');
    } else {
      document.documentElement.classList.remove('themeDark');
    }
    setHideFooter(hideFooter);
    setSplitNav(splitNav);

    return () => {
      setHideFooter(false);
    };
  }, [themeDark, splitNav, hideFooter]);

  return (
    <>
      <PageMeta {...meta} />
      {pageBlocks && <BlockZone {...pageBlocks} />}
    </>
  );
};

export default PageTemplate;

export const pageQuery = graphql`
  query standardPageQuery($slug: String!) {
    page: sanityPage(slug: { current: { eq: $slug } }) {
      meta {
        ...MetaCardFields
      }
      pageBlocks {
        ...BlockZone
      }
      themeDark
      hideFooter
      splitNav
    }
  }
`;
